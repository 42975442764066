<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getSuppsNow()"
          label-width="120px">
          <el-row>
            <el-col :md="8">
              <el-form-item label="供应商编号:" prop="supp_no">
                <el-input size="small" v-model.trim="searchForm.supp_no" clearable placeholder="请填写供应商编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="供应商简称:" prop="supp_abbr">
                <el-input size="small" v-model.trim="searchForm.supp_abbr" clearable placeholder="请填写供应商名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="供应商类型:" prop="supp_type">
                <el-select v-model="searchForm.supp_type" placeholder="请选择供应商类型" size="small" clearable>
                  <el-option v-for="item in suppType" :key="item.id" :label="item.label" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item prop="supp_cont" label="联系人:">
                <el-input v-model="searchForm.supp_cont" size="small" clearable placeholder="请输入联系人"></el-input>
              </el-form-item>
            </el-col>
              <el-col :md="8">
              <el-form-item prop="supp_phone" label="联系电话:">
                <el-input v-model="searchForm.supp_phone" size="small" clearable placeholder="请输入联系电话"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item prop="status" label="单据状态:">
                <el-select v-model="searchForm.status" clearable size="small" placeholder="请选择单据类型">
                  <el-option v-for="item in statusList" :key="item.id" :label="item.label" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="录入人:" prop="stff_id">
                <el-select filterable v-model="searchForm.stff_id" placeholder="请选择录入人" size="small" clearable>
                  <el-option v-for="item in stffUserList" :key="item.stff_id" :label="item.stff_name"
                    :value="item.stff_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          <!-- </el-row>
          <el-row> -->
            <el-col :md="16">
              <el-form-item label="录入时间:" prop="time">
                <div class="block">
                  <el-date-picker size="small" v-model="searchForm.time" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期">
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getSuppsNow()" class="vg_ml_16">查询
                </el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i
                    class="el-icon-refresh-right"></i> 刷新</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <!-- <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增
        </el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled='!btn.delete'><i class="el-icon-delete"></i>
          删除</el-button> -->
      </div>
      <el-row>
        <el-col :md="24">
          <el-table class="vg_cursor" ref="multiTable" :data="tableData" @selection-change="handleSelectionChange"
            @select="selectRows" @select-all="selectRows" @row-dblclick="dbClickJp" border v-loading="loadFlag">
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="累计订单量" prop="supp_order_total" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.supp_ordr_total">{{scope.row.supp_ordr_total}}</span>
                <span v-else>0</span>
              </template>
            </el-table-column>
            <el-table-column label="供应商编号" prop="supp_no" align="center" />
            <el-table-column label="供应商类型" prop="supp_type" :formatter="formType" align="center" />
            <el-table-column label="供应商简称" prop="supp_abbr" align="center" />
            <el-table-column label="供应商全称" prop="supp_name" align="center" />
            <el-table-column label="供应商地址" prop="supp_addr" align="center" />
            <el-table-column label="主营类目" prop="supp_maincate" >
              <template slot-scope="scope">
                <span v-if="scope.row.supp_maincate">
                  {{ scope.row.supp_maincate}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="联系人" prop="supp_contact" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.supp_contact || scope.row.supp_phone">
                  <div v-if="scope.row.supp_contact">
                    <span >
                      {{ scope.row.supp_contact }}
                    </span>
                  </div>
                  <div v-if="scope.row.supp_phone">
                    <span >
                      {{ scope.row.supp_phone }}
                    </span>
                  </div>
                </div>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip align="center" />
            <el-table-column label="录入时间" prop="create_time" :formatter="formatDate" align="center" />
            <el-table-column label="审批状态" :show-overflow-tooltip='true' align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
                  {{ helper.getStatusName(scope.row.status).name }}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage='totalPage' @changePageSearch="changePageSearch" ref="pubPagination">
          </pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { suppAPI } from "@api/modules/supp";
import { optnAPI } from "@api/modules/optn";
import pubPagination from "@/components/common/pubPagination";
import { stffAPI } from "@api/modules/staff"
export default {
  name: "SuppList",
  components: {
    pubPagination,
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        page_no: 1,
        supp_maincate: null,
        supp_id: null,
        supp_no: null, //供应商编号 
        supp_abbr: null, // 供应商 简称 
        supp_type: null, // 供应商类型 
        supp_cont: null,  //联系人 
        status: null, // 单据状态
        stff_id: null, //录入人
        time: null, // 录入时间
        supp_phone:null //联系电话 
      },
      totalPage: 0,
      btn: {},
      loadFlag: false,
      multiSelection: [],
      currentPage: 1,
      suppType: [
        { id: 1, label: '加工厂' },
        { id: 2, label: '面料厂' },
        { id: 3, label: '配套厂' },
        { id: 4, label: '辅料厂' },
      ],
      statusList: [{
        id: 0, label: '草拟'
      }, {
        id: 1, label: '在批'
      }, {
        id: 2, label: '生效'
      },],
      stffUserList: [],
    }
  },
  created() {
    if(this.$route.query.supp_id){
      this.searchForm.supp_id = Number(this.$route.query.supp_id)
    }
    this.initData()
  },
  watch: {
    $route(to, from) {
      if (from.path === '/supp_add' || from.path === '/supp_edit') {
        this.initData()
      }
    },
  },
  methods: {
    initData() {
      this.getSuppsList()
      this.getStffUser()
    },
    // 获取供应商信息
    getSuppsList() {
      const timeNewVal = this.helper.getTime(this.searchForm.time)
      get(suppAPI.getSupps, {
        supp_id: this.searchForm.supp_id,
        supp_no: this.searchForm.supp_no,
        supp_type: this.searchForm.supp_type,
        supp_maincate: this.searchForm.supp_maincate,
        page_no: this.searchForm.page_no,
        status: this.searchForm.status,
        supp_abbr: this.searchForm.supp_abbr,
        stff_id: this.searchForm.stff_id,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
        supp_contact: this.searchForm.supp_cont,
        supp_phone:this.searchForm.supp_phone
      })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        })
    },
    // 刷新
    buttonRefresh() {
      this.$refs.searchForm.resetFields()
      this.searchForm.supp_id = null
      this.loadFlag = true
      this.$refs.pubPagination.currentPage = 1
      this.initData()
    },
    // 查询方法
    getSuppsNow() {
      this.loadFlag = true
      this.$refs.pubPagination.currentPage = 1
      this.searchForm.page_no = 1
      this.getSuppsList()
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection)
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id
      this.jump('/supp_edit',{perm_id:permId,form_id:row.supp_id})
    },
    // //新增
    addRow() {
      const permId = this.$route.query.perm_id
      this.jump(`/supp_add?perm_id=${permId}`)
    },
    // 分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val
      this.getSuppsList()
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time)
    },
    // 类型转换
    formType(val) {
      if (val.supp_type === 1) {
        return '加工厂'
      } else if (val.supp_type === 2) {
        return '面料厂'
      } else if (val.supp_type === 3) {
        return '配套厂'
      } else if (val.supp_type === 4) {
        return '辅料厂'
      }
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' })
      } else {
        let temp = false
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'error', message: '此单据已生效或者正在审批中,无法删除！' })
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox()
        }
      }
    },
    //供应商信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const ids = []
        this.multiSelection.map((item) => {
          ids.push(item.supp_id)
        })
        post(suppAPI.deleteSuppByIds, { 'supp_id_list': ids })
          .then(res => {
            let mg = res.data.msg;
            if (res.data.code === 0) {
              let tp = 'success';
              this.$message({ message: mg, type: tp });
              if (ids.length === this.tableData.length) {
                if (this.currentPage > 1) {
                  this.currentPage = this.currentPage - 1
                  this.$refs.pubPagination.currentPage = this.currentPage
                }
              }
              this.initData()
            } else {
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData()
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData()
          })
      }).catch(() => {
        let mg = '取消删除！'
        let tp = 'info'
        this.$message({ message: mg, type: tp })
      })
    },
    //获取录取人信息
    getStffUser() {
      get(stffAPI.getAllStffsV1).then(res => {
        if (res.data.code === 0) {
          this.stffUserList = res.data.data
          return
        }
        this.$message.error(res.data.msg)
      }).catch(res => {
        this.$message.error(res.data.msg)
      })
    },
  }
}
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
